import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import store from './store'
import { sync } from 'vuex-router-sync'
import VueMeta from 'vue-meta'
import VueClipboard from 'vue-clipboard2'
import VueI18n from 'vue-i18n'
// import './registerServiceWorker'

const data = require('./message.json')

Vue.config.productionTip = false

sync(store, router)

Vue.use(VueMeta)
Vue.use(VueClipboard)
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: navigator.language.split('-')[0], // デフォルト言語はjaにしておくが、ブラウザの言語を拾ってきてここに入れる => 言語変更されたら書き換える
  fallbackLocale: 'ja',
  messages: data,
})

new Vue({
  i18n: i18n,
  router,
  vuetify,
  store,
  methods: {
    handleClick_changeLanguage (lang) {
      this.$i18n.locale = lang
    },
  },
  render: h => h(App),
}).$mount('#app')
