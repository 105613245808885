// Imports
import Vue from 'vue'
import Router from 'vue-router'
import { trailingSlash } from '@/util/helpers'
import {
  layout,
  route,
} from '@/util/routes'
import VueGtag from 'vue-gtag'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
        sessionStorage.setItem('positionY', savedPosition.y)
        return savedPosition
    } else {
        sessionStorage.setItem('positionY', 0)
        return { x: 0, y: 0 }
    }
  },
  routes: [
    layout('Default', [
      route('Housingers'), // name, component, path

      // Pages

      // Components
      route('HighProfile', null, 'components/high-profile'), // name, component, path
      route('HallOfFame', null, 'components/hall-of-fame'),
      route('Concept', null, 'components/concept'),
      route('ConceptTool', null, 'components/concept-tool'),
      route('Technique', null, 'components/technique'),
      // route('Gradually', null, 'components/gradually'),
      route('Help', null, 'components/help'),
      route('Q&A', null, 'components/q&a'),
      // route('Tweet', null, 'components/tweet'),
      // route('EdenTimeline', null, 'components/eden-timeline'),
      route('Artwork', null, 'components/artwork/:id'),
      route('Tool', null, 'components/tool/:tabid'),
      route('Aetheryte', null, 'components/aetheryte'),
      route('Policy', null, 'components/policy'),
      route('Commission', null, 'components/commission/:tabid'),

      // Tables
      // route('Regular Tables', null, 'tables/regular'),

      // Maps
      // route('Google Maps', null, 'maps/google'),

    ]),
  ],
})

router.beforeEach((to, from, next) => {
  return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
})

Vue.use(VueGtag, {
  config: { id: 'G-KZSTZTKFZG' },
}, router)

export default router
