// Vue
import Vue from 'vue'
import Vuex from 'vuex'
import pathify from '@/plugins/vuex-pathify'

// Modules
import * as modules from './modules'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  plugins: [
    pathify.plugin,
  ],
  housingersOptions: {},
  housingersItems: [],
  housingersPage: 1,

  artworkOptions: {},
  artworkItems: [],
  artworkPage: 1,

  hallOfFameOptions: {},
  hallOfFameItems: [],
  hallOfFamePage: 1,

  highProfileOptions: {},
  highProfileItems: [],
  highProfilePage: 1,

  conceptOptions: {},
  conceptItems: [],
  conceptPage: 1,

  techniqueOptions: {},
  techniqueItems: [],
  techniquePage: 1,

  commissionOptions: {},
  commissionItemsOn: [],
  commissionItemsOff: [],
  commissionPage: 1,
})

store.subscribe(mutation => {
  if (!mutation.type.startsWith('user/')) return

  store.dispatch('user/update', mutation)
})

store.dispatch('app/init')

export default store

export const ROOT_DISPATCH = Object.freeze({ root: true })
