<template>
  <div>
    <v-list-item>
      <v-list-item-icon />
      <v-list-item-content>
        <a
          href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3580315&pid=887339694"
          rel="nofollow"
        ><img
          src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=3580315&pid=887339694"
          border="0"
        ></a>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon />
      <v-list-item-content>
        <a
          href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3580315&pid=887339517&vc_url=https%3A%2F%2Fstore.jp.square-enix.com%2Fcategory%2F11%2FSEDL_1103.html"
          rel="nofollow"
        ><img
           src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=3580315&pid=887339517"
           height="1"
           width="0"
           border="0"
         >{{ $t("message.advertisement_1") }}<br>
          {{ $t("message.advertisement_2") }}</a>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
  export default {
    name: 'Estore',
  }
</script>
